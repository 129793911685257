import axios from 'axios'

export default {
    role: async (token) => {
        const url = process.env.VUE_APP_API + 'ref/role'

        const response = await axios.get(url, {
            headers: {
                Authorization: "Bearer " + token,
            },
        })

        return response.data.data
    },

    Kec: async (token) => {
        const url = process.env.VUE_APP_API + 'ref/kecamatan'

        const response = await axios.get(url, {
            headers: {
                Authorization: "Bearer " + token,
            },
        })

        return response.data.data
    },

    Kel: async (token, kec) => {
        const url = process.env.VUE_APP_API + 'ref/kelurahan/' + kec

        const response = await axios.get(url, {
            headers: {
                Authorization: "Bearer " + token,
            },
        })

        return response.data.data
    },

    Tps: async (token, kel) => {
        const url = process.env.VUE_APP_API + 'ref/tps/' + kel

        const response = await axios.get(url, {
            headers: {
                Authorization: "Bearer " + token,
            },
        })

        return response.data.data
    },
}