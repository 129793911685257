<template>
  <v-dialog v-model="ModalAdd" :max-width="dekstop" persistent>
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="utama"
            fixed
            bottom
            right
            fab
            dark
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="utama" dense flat>
        <v-toolbar-title>Tambah Data</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-col cols="12" md="12">
            <v-row class="mb-n8">
              <v-col cols="12">
                <v-text-field
                  v-model="tps_nama"
                  outlined
                  dense
                  :rules="fRules"
                  label="Nama TPS"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="6">
                <v-text-field
                  v-model="tps_lat"
                  outlined
                  dense
                  :rules="fRules"
                  label="Lat"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="tps_lng"
                  outlined
                  dense
                  :rules="fRules"
                  label="Lng"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="tps_pemilih"
                  outlined
                  dense
                  :rules="fRules"
                  label="Total Jumlah Pemilih"
                  autocomplete="off"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <hr />

          <div class="text-right mt-5">
            <v-btn
              color="primary"
              depressed
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import refreshView from "@/store/tps/view";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "60%",
    lbtn: false,
    show: false,
    ModalAdd: false,

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
    urlImage: process.env.VUE_APP_API_BASE + "upload/user/default-user.png",
    fieldfoto: null,

    refKec: [],
    refKel: [],

    tps_nama: "",
    tps_lat: "",
    tps_lng: "",
    tps_pemilih: "",
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refKec = await getRef.Kec(this.session.token);
      this.ModalAdd = true;
    },

    default() {
      this.tps_nama = "";
      this.tps_lat = "";
      this.tps_lng = "";
      this.tps_pemilih = "";
    },

    simpan() {
      this.lbtn = true;

      const data = {
        tps_nama: this.tps_nama,
        tps_lat: this.tps_lat,
        tps_lng: this.tps_lng,
        tps_koordinat: "latLng(" + this.tps_lat + "," + this.tps_lng + ")",
        tps_pemilih: this.tps_pemilih,
        kec_id: this.$route.query.kec_id,
        kel_id: this.$route.query.kel_id,
      };

      const url = process.env.VUE_APP_API + "tps";
      this.axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.lbtn = false;
          this.closeModal();
        });
    },

    async Ckec(value) {
      this.refKel = await getRef.Kel(this.session.token, value);
    },

    closeModal() {
      this.default();
      this.ModalAdd = false;
    },
  },
};
</script>
