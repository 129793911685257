import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalEdit: false,
        
        data: {}
    },

    mutations: {
        toggleModal(state, value) {
            state.modalEdit = value
        },

        viewModal(state, value) {
            state.data = value
        }
    }
})