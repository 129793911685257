<template>
  <v-dialog v-model="modalEdit" :max-width="dekstop" persistent>
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Edit TPS</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-col cols="12" md="12">
            <v-row class="mb-n8">
              <v-col cols="12">
                <v-text-field
                  v-model="item.tps_nama"
                  outlined
                  dense
                  :rules="fRules"
                  label="Nama TPS"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="6">
                <v-text-field
                  v-model="item.tps_lat"
                  outlined
                  dense
                  :rules="fRules"
                  label="Lat"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.tps_lng"
                  outlined
                  dense
                  :rules="fRules"
                  label="Lng"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="6">
                <v-select
                  v-model="item.kec_id"
                  outlined
                  dense
                  :items="refKec"
                  item-value="kec_id"
                  item-text="kec_nama"
                  :rules="fRules"
                  label="Kecamatan"
                  autocomplete="off"
                  @change="Ckec"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="item.kel_id"
                  outlined
                  dense
                  :items="refKel"
                  item-value="kel_id"
                  item-text="kel_nama"
                  :rules="fRules"
                  label="Kelurahan"
                  autocomplete="off"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.tps_pemilih"
                  outlined
                  dense
                  :rules="fRules"
                  label="Total Jumlah Pemilih"
                  autocomplete="off"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <hr />

          <div class="text-right mt-5">
            <v-btn
              color="primary"
              depressed
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import modalEdit from "@/store/tps/edit";
import refreshView from "@/store/tps/view";

export default {
  computed: {
    modalEdit: {
      get() {
        return modalEdit.state.modalEdit;
      },
      set(value) {
        modalEdit.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modalEdit.state.data;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalEdit() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refKec = await getRef.Kec(this.session.token);
      this.refKel = await getRef.Kel(this.session.token, this.item.kec_id);
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "60%",
    lbtn: false,
    show: false,

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],

    refKec: [],
    refKel: [],
    
  }),

  methods: {
    default() {
    },

    async simpan() {
      this.lbtn = true;

      const data = {
        tps_id: this.item.tps_id,
        tps_nama: this.item.tps_nama,
        tps_lat: this.item.tps_lat,
        tps_lng: this.item.tps_lng,
        tps_koordinat: "latLng(" + this.item.tps_lat + "," + this.item.tps_lng + ")",
        tps_pemilih: this.item.tps_pemilih,
        kec_id: this.item.kec_id,
        kel_id: this.item.kel_id,
      };

      const url = process.env.VUE_APP_API + "tps";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.lbtn = false;
          this.closeModal();
        });
    },

    async Ckec(value) {
      this.refKel = await getRef.Kel(this.session.token, value);
    },

    closeModal() {
      this.default();
      this.modalEdit = false;
    },
  },
};
</script>
