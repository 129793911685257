<template>
  <v-dialog v-model="modalDetail" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form" v-if="item">
        <div class="pa-5">
          <v-col cols="12" md="12">
            <v-row class="mb-n8">
              <v-col cols="12">
                <v-text-field
                  v-model="item.tps_nama"
                  outlined
                  dense
                  readonly
                  filled
                  label="Nama TPS"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="6">
                <v-text-field
                  v-model="item.tps_lat"
                  outlined
                  dense
                  readonly
                  filled
                  label="Lat"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.tps_lng"
                  outlined
                  dense
                  readonly
                  filled
                  label="Lng"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="6">
                <v-select
                  v-model="item.kec_id"
                  outlined
                  dense
                  readonly
                  filled
                  :items="refKec"
                  item-value="kec_id"
                  item-text="kec_nama"
                  label="Kecamatan"
                  autocomplete="off"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="item.kel_id"
                  outlined
                  dense
                  readonly
                  filled
                  :items="refKel"
                  item-value="kel_id"
                  item-text="kel_nama"
                  label="Kelurahan"
                  autocomplete="off"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.tps_pemilih"
                  outlined
                  dense
                  readonly
                  filled
                  label="Total Jumlah Pemilih"
                  autocomplete="off"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import modalDetail from "@/store/tps/detail";

export default {
  computed: {
    modalDetail: {
      get() {
        return modalDetail.state.modalDetail;
      },
      set(value) {
        modalDetail.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modalDetail.state.data;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  watch: {
    async modalDetail() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refKec = await getRef.Kec(this.session.token);
      this.refKel = await getRef.Kel(this.session.token, this.item.kec_id);
    },
  },

  data: () => ({
    session: "",
    CWidth: "60%",

    refKec: [],
    refKel: [],
  }),

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/user/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/user/default-user.png";
      }
    },

    closeModal() {
      this.modalDetail = false;
    },
  },
};
</script>
